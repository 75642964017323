<script>
  import Block from "../../components/Block.svelte";
  import Text from "../../components/Text.svelte";
  import Divider from "../../components/Divider.svelte";
  import Quote from "../../components/Quote.svelte";
  import Container from "../../components/Container.svelte";
</script>

<style>
  .textContainer {
    margin: 0px 40px;
  }

  .image {
    width: 500px;
    height: 300px;
    min-width: 500px;
    margin-left: 20px;
    object-fit: contain;
  }

  .textImageContainer {
    display: flex;
    align-items: center;
    width: 100%;
  }

  @media (max-width: 800px) {
    .textImageContainer {
      flex-direction: column-reverse;
    }

    .image {
      margin-left: 0px;
      width: 100%;
      max-width: 300px;
      min-width: 300px;
      height: 200px;
    }
  }
</style>

<Container>
  <div class="textContainer">
    <Quote
      author="T.K.V. Desikachar"
      quote="„Durch Yoga gehst du nur auf dich selbst zu, nirgendwo anders hin
      kann es gehen.“" />
  </div>
  <Block fit="contain" imgSrc="runs.jpeg" title="Bea" text=" " direction="left">
    <Text>
      Yoga ist ein Weg der Selbsterfahrung. Mein erster Kontakt mit Yoga war ein
      Yogakurs während meiner ersten Schwangerschaft. Mit der Geburt unserer
      Tochter begann eine bewegte Zeit, die Freude und die Verantwortung Mama zu
      sein und gleichzeitig mein Medizinstudium weiterzuführen, füllten mein
      Leben aus. Nach der Geburt unseres Sohnes entschied ich mich, mein
      Medizinstudium nach 10 Semestern und mit dem 1. Staatsexamen zu beenden
      und mich ganz meiner Familie zu widmen. Unser zweiter Sohn kam zur Welt
      und ich lebte meine Aufgabe als Mama und Partnerin und Hausfrau mit
      Hingabe. Zum Ausgleich war ich als Übungsleiterin im Verein und als
      Trainerin im Fitnessstudio tätig und genoss den Kontakt mit den Menschen
      und die Bewegung.
    </Text>
  </Block>
  <div class="textContainer">

    <Text>
      Zur Freude an der Bewegung kam mehr und mehr auch das Bedürfnis nach
      Entspannung, und so entschied ich mich 2004 für eine Ausbildung zur
      Yogalehrerin am Gesundheitszentrum Ulm. Ich bedanke mich von ganzem Herzen
      bei allen, von denen ich lernen durfte und die mir ein vielseitiges
      Verständnis von Yoga vermittelten. Gleichwohl empfand ich das Wissen und
      das Verständnis vom Aufbau und der Funktion des menschlichen Körpers aus
      meinem Medizinstudium als Bereicherung bei meinem Lernen im Yoga.
      <br />
      Seit 2006 gebe ich meine Yogaerfahrung mit Freude und Hingabe in meinen
      Yogakursen weiter, in den Jahren 2007-2009 auch in Mexiko, wo ich mit
      meiner Familie eine wunderbare Zeit verbrachte. Seit 2012 bin ich
      selbständige Yogalehrerin. Im September 2021 schloss ich die Ausbildung in
      SVASTHA Yogatherapie bei Dr. Günter Niessen und Ganesh Mohan erfolgreich ab. Die klare und tiefgehende Weitergabe ihrer Erfahrungen und ihres Wissens in den Bereichen Yoga, Medizin, Ayurveda und Psychologie
      unterstützt mich und trägt mich in meiner Tätigkeit als Yogalehrerin und
      in meinem ganzen Menschsein. Von Herzen „danke“ Ganesh und Günter. Und so
      möchte ich mich bei der ganzen Familie Mohan bedanken für ihre warme und
      achtsame Yoga Anleitung und die wunderbare Erläuterung der Yogalehre in
      der Tradition von Sri T.Krishnamacharya.
      <br />
      Von Herzen danke dir, Cornelia, für die wertvollen Stunden der
      Supervision, du unterstützt mich als Therapeutin und Freundin.
      <br />
      In den Einzelstunden bei Richard Neururer erfahre ich Anleitung und
      Unterstützung in meiner persönlichen Yogapraxis, danke Richard.
    </Text>
    <Quote
      quote="„Yoga bedeutet, sich gut um sich selbst zu kümmern.“"
      author="Nitya Mohan" />
    <div class="textImageContainer">
      <Text>
        Diese Worte von Nitya begleiten mich durch mein Yoga Üben und durch mein
        Leben. So verstehe ich die Yogapraxis als eine Form der Selbstfürsorge
        und auch der Selbstverantwortung. Herauszufinden was ich TUN kann um
        mein Gleichgewicht zu finden, was mich unterstützt und nährt und mich
        Mensch sein, Bea sein lässt. Herauszufinden was ich LASSEN kann, was mir
        nicht gut tut, mich blockiert und mich einschränkt. Wann ist es gut, es
        „gut sein zu lassen“ und zu akzeptieren was ist?
        <br />
        Ich kann TUN und LASSEN was ich will. Es ist meine Entscheidung und
        meine Verantwortung.
      </Text>
      <img class="image" src="yoga-figuren-bg.png" alt="Morgengruß" />
    </div>

    <Quote
      quote="„Je behutsamer ein Mensch handelt, desto mehr werden andere
      Menschen in seiner Gegenwart liebevolle Gefühle empfinden.“"
      author="YOGASUTRA 2.35" />
    <Text>
      Patanjali beschreibt im YOGASUTRA die Qualität von „ahimsa“, die
      Gewaltlosigkeit. T.K.V. Desikachar übersetzt: „Je behutsamer ein Mensch
      handelt, desto mehr werden andere Menschen in seiner Gegenwart liebevolle
      Gefühle empfinden.“ Der behutsame und freundliche Umgang mit mir selbst
      und mit den anderen Menschen ist eine Qualität, die ich im Yoga üben und
      in mein Leben mehr und mehr integrieren kann… Yoga kann uns helfen, in
      Verbindung mit uns selbst zu kommen. In dieser Verbindung mit uns selbst
      spüren wir die Verbindung mit allen und allem. In Verbundenheit freue ich
      mich auf eine Begegnung mit dir.
      <br />
      Namasté, Bea
    </Text>
  </div>

</Container>
