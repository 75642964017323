<script>
  import Container from "../../components/Container.svelte";
  import Divider from "../../components/Divider.svelte";
</script>

<style>
  span {
    line-height: 30px;
  }

  h4 {
    text-transform: none;
    font-weight: bold;
    font-size: 16px;
  }
</style>

<Container>
  <h1>Impressum</h1>
  <Divider />

  <h4>Angaben gemäß § 5 TMG</h4>
  <span>Beate Ilg-Wohnhaas</span>
  <br />
  <span>Wacholderweg 10</span>
  <br />
  <span>73326 Deggingen</span>

  <h4>Kontakt</h4>
  <span>Telefon: +49 160 1578254</span>
  <br />
  <span>E-Mail: beate.wohnhaas@t-online.de</span>

  <h4>Umsatzsteuer-Identifikationsnummer</h4>
  <span>
    Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz: DE
    299582018
  </span>

  <h4>Angaben zur Berufshaftpflichtversicherung</h4>
  <h5>Name und Sitz des Versicherers</h5>
  <span>AMEXPool AG</span>
  <br />
  <span>Im Mittelfeld 19</span>
  <br />
  <span>79426 Buggingen</span>
  <h5>Geltungsraum der Versicherung</h5>
  <span>Deutschland</span>

  <h4>Haftung für Inhalte</h4>
  <span>
    Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf
    diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10
    TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte
    oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
    forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen
    zur Entfernung oder Sperrung der Nutzung von Informationen nach den
    allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung
    ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
    Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
    Rechtsverletzungen werden wir diese Inhalte umgehend entferne
  </span>
  <h4>Haftung für Links</h4>
  <span>
    Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte
    wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch
    keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der
    jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten
    Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
    überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
    erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist
    jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar.
    Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
    entfernen.
  </span>
  <h4>Urheberrecht</h4>
  <span>
    Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten
    unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung,
    Verbreitung und jede Art der Verwertung außerhalb der Grenzen des
    Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors
    bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den
    privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf
    dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
    Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
    gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung
    aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
    Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend
    entfernen.
  </span>
</Container>
