<style>
  .container {
    padding: 20px;
    padding-top: 80px;
  }
  @media (max-width: 800px) {
    .container {
      padding: 10px;
      padding-top: 70px;
    }
  }
</style>

<div class="container">
  <slot />
</div>
