<script>
</script>

<style>
  p {
    text-align: justify;
    color: var(--text-color);
    line-height: 30px;
  }
</style>

<p>
  <slot />
</p>
